<template>
  <div class="clinic-dashboard-container">
    <vs-popup title="" :active.sync="showAssignModal">
      <h4>Assign An Owner</h4>

      <vs-row vs-justify="center" class="available-followers-list">
        <vs-col class="vx-col w-full md:w-1/2 sm:w-1/2 mb-base" vs-w="6" v-bind:key="item.id"
                v-for="item in availableFollowers">
          <div @click="assignFollower(item)">
            <vs-card class="available-followers-item text-center cursor-pointer">
              <div class="available-followers-item-content">
                <div class="follower-name">{{ item.name }}</div>
                <div class="follower-role">{{ item.role !== null ? item.role.toUpperCase() : '' }}</div>
              </div>
            </vs-card>
          </div>

        </vs-col>
      </vs-row>
    </vs-popup>
    <section class="registration-tracking">
      <h1 class="registration-tracking-title">Checklist</h1>
      <p class="requests_count">You have
        {{ parseInt(phoneRegistrationTotalLeads) + parseInt(admissions.length) }}
        pending Pre-Op items
      </p>

      <p class="requests_count">You have
        {{ parseInt(phoneRegistrationTotalLeads) + parseInt(admissions.length) }}
        pending Post-Op items
      </p>
    </section>

    <section class="request-statistics">
    <p class="followup-rules-description">These checklits are to help to improve communication and teamwork by bringing together the careteam involved in care to confirm that critical care and safety measures are performed before, during and after an operation.</p>
  </section>

    <section class="request-statistics">
      <div class="statistics-card">
        <statistics-card-line icon="UsersIcon" :statistic="phoneRegistrationTotalLeads"
                              statisticTitle="Pre-Op"
                              :chartData="subscribersGained.series" type="area"></statistics-card-line>
      </div>
      <div class="statistics-card">
        <statistics-card-line icon="UsersIcon" :statistic="admissions.length"
                              statisticTitle="Post-Op"
                              :chartData="subscribersGained.series" type="area"></statistics-card-line>
      </div>
    </section>
    <section class="followup-rules">
      <p class="followup-rules-description">The following activities are mandatory for follow up claims and in order to
        provide a better patient experience:</p>
      <p class="followup-rule-item">
        <span class="followup-rule-icon">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17"
               viewBox="0 0 17 17">
            <image width="17" height="17"
                   xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAcCAYAAACdz7SqAAAC8ElEQVRIia2We2hPYRjHP5vJZRN/2B+zicglUYgol1jhl4jIJaIIf8wlEzHWImO5LpR7KUQTLaNs9gdl+W9KyDVt5lIk1+SyP/TU9+jtOOf8zq/Ot07vc973Pe/3fZ/ne57nzcqq2EGCmAKUAGOBrsALoBbYC/z2aHISJDwAbPD1jdSzDJgEtFlndkKEax3CRmAhkAJ2q68fcNfjS4I0Fzgs+zwwFagBGoBtwCigHSgCSpMina72K7AkYLwZ2C97aVKkg5zFw1Cv/t5JkX5RWxQxxxv7ngnpOIljJlDoG2tUOwCYHfL9ZrV34pAu1r/WBFwE6iT7k0BHzXkCXJd9RZvroPeBwDVgmN4r05FulRr76/2VxJIFrFQMO2nMPPBG69nmWrWZp8AMzVkNPI4iHQ7skn0VGAz0kWtL1G+73yO3ngF6ON8XOgLzMB9YZXZYGqxVfJr1n/mxAjgV0G+nuw08V9orAEYrPXo4G5QG7fQTZFeGeOI0sN0R1UOgXF4JgsV2kzbbOci9tpFusttCFjG8VVsvV4cRGp5JB32BBUGk5pbXsotDFrGNDZVdHkHmhwksVEiX1FYoWftxDugC/AEeZEBKFGkZcA/IA+4rHlaa5jpV5Acwy62TcRFVT2+qFuapCPvRojmZ8rUHnTQll21Js8AQ4COw0clAUUgpptV+UovhDUckF4B5IiiQ+kxcVcAHoDuwT/9iFHKVKntZ4nHdawXXyxS3dBt4FLBQq8arlJHylQyiUCdvWPwXeaSTnURgKW15DHd9c1JiFEqdX8+E985zb43appiESPnrgDURcywsB2Uf84p5jq4Y+RqYE5MQpcBDjl0WMKdBbYvrlWznZMcljrh4rySBlF7t++6Ec2NIuQNG2lP25wwIDb900Tqq9/VO5Sn2yphCYHX1H8y9l/WL2G6nAT/TkFkR/6RYvlRxNlUaqVWRMc5NwZLHEf8CRroTGA9MBEZkcFL7xkgNplBLi3Yqj9BuCf/fmYC/yLGTdKVHzPkAAAAASUVORK5CYII="/>
          </svg>
        </span>
        <span>Recall and follow-up</span>
      </p>
      <p class="followup-rule-item">
        <span class="followup-rule-icon">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17"
               viewBox="0 0 17 17">
            <image width="17" height="17"
                   xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAB70lEQVRIia3VS4hOcRjH8c+4ZNhYqHErdxZKkZgmxc613AplZaTYYTFKmHLZIMWGomRhpcREuSzdiiRshCjXEgtSMzZKTz1Tb6fzvjNzXr96ezvn95zvc/7P8/z/p6Wl+7AaHcdevMIafNaEhtU8ehP7cAoj8Qmz/wf8er7pfOzHXDzBG8xoBn4M67AAL2q89kzwDlOrwg/gJJ6X+O15P5KOqQKX9a6nWNFYLKkKn9Qg5lz+v6wC/4OVdfwT2JX+tyrwmJQtJV70oQurcWeo4H54N1rRUfA6clW3qoD74W/xEZcL3oZMeqQZeGhTbpbaifieR8EhLG4GHpvlAe4X/NPZk8eYUhUeWo4WXCjERHme4gPmlDCidGdzZFvrwfsStAMbC4BFeITXWFtzfyJ+YTs68RNtZXBZgjO4inkFL/pxHj24lNdfc0Wt+fuS+6GtDB7ag9u5I2cVvJ1Yj83Zo2eFUs3M6YsE48vgoVXZ3AhcWPB68hBbUeLJZLGi9/XgoaW4ls3sLPHvNnh2ciRoBJeNPYqL2Y9RA8TXavRAcHk8LMtSxXGwe4D4GOffGDEYeOhevnVMUmysXhzEuEJcxPzIBNMHC+9XTNIEXMlyBShG8UZ+cGJlf3MU+4YKD8WYbcNwbMVDTMvjIb7HAe6Ffxq0Yem50VMDAAAAAElFTkSuQmCC"/>
          </svg>
        </span>
        <span>Phone call</span>
      </p>
      <p class="followup-rule-item">
        <span class="followup-rule-icon">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17"
               viewBox="0 0 17 17">
            <image width="17" height="17"
                   xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAEIUlEQVRYha2Xf2hWZRTHP++rrRbWLBMscYphSZgGWbbSpSx0RgRmU6dWplFEUYL1h2UzFdMyTSzCQCJtK38g0zKtjSwrZpurTFs/TBJ/YxLVrExHKge+F84e733fd1sHLue5z73P/Z57fnyf86RSFXPogNwPTALKgOb2fKZzB8AfBN7U+FtgOLD//zYgD1gNHAC2Aw3APmAG8LLe2QUMlB4GNAE36hoKNAKvJAFkC4H91SfB3G9AN43vBt4HqoCJmjsJ5Lv37f7iJIB0Fg+USW8C3gIOOfBSgaM8eFXjfP31MuAX3d+SBJAtBJOdIf9qfB3QAvwcvPsEsEp5cFxzR4CFwFjgyziAJA+kgEeAS4HvHTi6D8EjaXTgJtXSD8jw88R74DKgCLgLGA9crvm3E8BykT3ySG8l59fAemAL8IP9WKfU7SOi77wLzANuUtzMZa8DrwGnO2DEVuAMUAj0A0rk3R+B3T4Ev0qvA66VNxYAf2m+SNbXAtPaYICV5+PAlcBtwB+atwRtlQM10kflOi+DgTrgHuAOYAXwTALgBK0fHfOsTt49HSWlN+AL6UkxCxdITxPBnAXmx9R3qUJprr455jvm/gvlxbOhAcccyQwKFka1/7kSqUX3FwTgWzR+HohjuDHSjdGEN+BpB/RfsHCJ9B5ZbhS9AfizDeAmB6UfAq7xBlidvqTxSOC7YGElMEu0arJGpdoWcJMXgeVAT+VD97R2NaPZv+Wi2oTFFvMC4BIlmiXSKAc+Jwt4JI/KCPP29rQoFO1yG7IsbnFlaX/+ocZz9fe5ShTSq9NyuYEPEAfkIt7tBj5b42LgYdV7kpj7P9OzJWlxd5EI4l6xX16GD4Qxj8CXAtuAN1TSi2LW9lFf0QN4x/qKKAmPiYKbFaPBOYDPdjG3xuNJfWeZyvkp4NZgvSVyL2BzxDe+DPdG5KCxl05AeQA+1z0vll4hQyp1H4aiSbohmvAG3KAs3+32hUjy5LI4cJOPpR8DVrq9YlvwXkT3E+IMGCJdzflyUsk6MwbcpB54AeiqTrmLwtMQvNekXOuvcm7VD5QH+j3gK/e8NgNHmDyrNbYP/ATscM8uEmeMlpGon6zyBmzWlmkUWaFrp4hqZwbg0BP1wdx0GXeFm/tU/WVsV2yt9Z2KY3fFdEqOBoSSUjOC8sTC+5FP8kxt+VXAYZVmQTsNGCW2rE/qjDN1xUdUEderzneJsKzk/tHe4MW4/Tl1OnUK2zg9X5UEkq0tX6kTUE1w2DDp68qtj0qu0D0/5Rh1YxJAtoNJdPDI1weNiBYDJ4CpOhuWyDuFcrcx4TfqfFKqiMNJANk8YA2I7fvmVuuEooSy7dQ2FKsQu0yM/e5za63NNyo2ek6UXE7Ha2PmLIvtQGoZbbuote52YPXyO/BBxi8D5wD5DvcaRx5T/QAAAABJRU5ErkJggg=="/>
          </svg>
        </span>
        <span>Offer</span>
      </p>
    </section>

    <section class="registration-list xs:m-4 sm:m-4">
      <div class="registration-list-wrapper sm:pl-30 xs:p-4">
        <div class="registration-list-header xs:flex-col sm:flex-col xs:text-center sm:text-center">
          <div class="registration-title">
            Preoperative Checklist
          </div>
          <div class="registration-subtitle">
            For clinicians working within their scope of clinical practice and is designed to aid patient preparation prior care delivery
          </div>
        </div>
        <div class="registration-list-content">
          <vs-table v-for="(post, index) in preOps" :key="index" v-model="selected" pagination :data="post.tasks">
            <template slot="thead">
              <vs-th>Activity</vs-th>
              <vs-th>Doctype</vs-th>
              <vs-th>Duration</vs-th>
              <vs-th>Name</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in post.tasks">
                <vs-td :data="data[indextr].activity">
                  {{ data[indextr].activity }}
                </vs-td>

                <vs-td :data="data[indextr].doctype">
                  {{ data[indextr].doctype }}
                </vs-td>

                <vs-td :data="data[indextr].task_duration">
                  {{ data[indextr].task_duration }}
                </vs-td>
                <vs-td>
                  {{ data[indextr].name }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </section>

    <section class="registration-list followup-list xs:m-4 sm:m-4">
      <div class="registration-list-wrapper sm:pl-30 xs:p-4">
        <div class="registration-list-header xs:flex-col sm:flex-col xs:text-center sm:text-center">
          <div class="registration-title">
            Postoperative Checklist
          </div>
          <div class="registration-subtitle">
            For clinicians working within their scope of clinical practice and is designed to aid patient preparation post care delivery
          </div>
        </div>
        <div class="registration-list-content">
          <vs-table v-for="(post, index) in postOps" :key="index" v-model="selected" pagination :data="post.tasks">
            <template slot="thead">
              <vs-th>Activity</vs-th>
              <vs-th>Doctype</vs-th>
              <vs-th>Duration</vs-th>
              <vs-th>Name</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in post.tasks">
                <vs-td :data="data[indextr].activity">
                  {{ data[indextr].activity }}
                </vs-td>

                <vs-td :data="data[indextr].doctype">
                  {{ data[indextr].doctype }}
                </vs-td>

                <vs-td :data="data[indextr].task_duration">
                  {{ data[indextr].task_duration }}
                </vs-td>
                <vs-td>
                  {{ data[indextr].name }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import analyticsData from './ui-elements/card/analyticsData.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import VxTimeline from '@/components/timeline/VxTimeline'
import vSelect from 'vue-select'
import Prism from 'vue-prism-component'
import modulePatientFollower from '@/store/patientFollower/modulePatientFollower.js'
import axios from "axios";

export default {
  data() {
    return {
      showAssignModal: false,
      userId: null,
      checkpointReward: {},
      subscribersGained: {},
      ordersRecevied: {},
      salesBarSession: {},
      supportTracker: {},
      productsOrder: {},
      salesRadar: {},
      phoneRegistrationTotalLeads: "0",
      phoneRegistrationLeads: [],
      activities: [],
      selected: [],
      analyticsData,
      dispatchedOrders: [],
      preOps: [],
      postOps: [],
    }
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline,
    vSelect,
    Prism
  },
  methods: {
    showAssignFollowerModal(id) {
      this.userId = id;
      this.showAssignModal = true;
    },
    assignFollower(item) {
      let payloadObj = {};
      payloadObj.payload = { owner: item.name };
      payloadObj.params = {
        attributesToBeUpdated: 'owner',
        id: this.userId
      };
      this.$store.dispatch("modulePatientFollower/assignFollower", payloadObj)
        .then(() => {
          this.showAssignModal = false;
          this.$vs.notify({
            title: 'Assign Follower',
            text: 'Follower assigned successfully!',
            color: 'success',
          })
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  computed: {
    availableFollowers() {
      return this.$store.state.patientFollower.availableFollowers;
    },
    admissions() {
      return this.$store.state.patientFollower.admissions;
    }
  },
  created() {
    // this.$http.get('/api/user/checkpoint-reward')
    //   .then((response) => {
    //     this.checkpointReward = response.data
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })

    // Subscribers gained - Statistics
    this.$http.get('/api/card/card-statistics/subscribers')
      .then((response) => {
        this.subscribersGained = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Orders - Statistics
    this.$http.get('/api/card/card-statistics/orders')
      .then((response) => {
        this.ordersRecevied = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Sales bar - Analytics
    this.$http.get('/api/card/card-analytics/sales/bar')
      .then((response) => {
        this.salesBarSession = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Support Tracker
    this.$http.get('/api/card/card-analytics/support-tracker')
      .then((response) => {
        this.supportTracker = response.data
      })
      .catch((error) => {
        console.log(error)
      })
    //Sign Up
    this.$http.get('https://sheets.googleapis.com/v4/spreadsheets/1qyClKXVwYHIlJl_UshTHrC06moFYyw_5UXz05Hc2p2Q/values/Sheet1!A1:N100?key=AIzaSyDUI40h3hMpPZcVPYEzins8en4PxcG9KSM')
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
    // Products Order
    this.$http.get('/api/card/card-analytics/products-orders')
      .then((response) => {
        this.productsOrder = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Sales Radar
    this.$http.get('/api/card/card-analytics/sales/radar')
      .then((response) => {
        this.salesRadar = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Dispatched Orders
    this.$http.get('/api/table/dispatched-orders')
      .then((response) => {
        this.dispatchedOrders = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // this.$http.get('/activity/getAll')
    //   .then((response) => {
    //     console.log(response.data.activitys);
    //     this.activities = response.data.activitys;
    //
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })

    // if (!modulePatientFollower.isRegistered) {
    //   this.$store.registerModule('modulePatientFollower', modulePatientFollower)
    //   modulePatientFollower.isRegistered = true
    // }
    // this.$store.dispatch("modulePatientFollower/getAdmissions");
    // this.$store.dispatch("modulePatientFollower/getAvailableFollowers");
    let req = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })

    req({
      url: '/api/method/frappe.desk.form.load.getdoc?doctype=Nursing%20Checklist%20Template&name=Pre-op%20Egg%20Session',
      method: 'get',
      headers: {
        'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`
      }
    }).then(res => {
      console.log("data ", res.data.docs)
      this.preOps = res.data.docs
    })

    req({
      url: '/api/method/frappe.desk.form.load.getdoc?doctype=Nursing%20Checklist%20Template&name=Post-op%20Egg%20Session',
      method: 'get',
      headers: {
        'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`
      }
    }).then(res => {
      console.log("data ", res.data.docs)
      this.postOps = res.data.docs
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.clinic-dashboard-container {
  max-width: 1400px;
  margin: 0 auto;

  .registration-tracking {
    margin: 0 35px;
    padding: 65px 0;
    background: linear-gradient(120deg, rgba(#01B1A2, 1), rgba(#037F82, 1));
    border-radius: 10px;

    .registration-tracking-title {
      color: $white;
      font-weight: 700;
      text-align: center;
      margin-bottom: 5px;
    }

    .requests_count {
      color: $white;
      font-size: 24px;
      text-align: center;
    }
  }

  .request-statistics {
    margin: 50px 35px 75px 35px;
    display: flex;

    .statistics-card {
      min-height: 300px;
      box-shadow: 2px 2px 8px #ddd;
      border-radius: 15px;

      .pb-0 {
        padding-bottom: 40px !important;
      }

      .feather-icon {
        visibility: hidden !important;
        padding: 0 !important;
      }

      &:last-child {
        margin-left: 45px;
      }

      .truncate {
        padding-left: 15px;

        h2 {
          color: $darkGreen;
          font-size: 38px;
          margin-bottom: 10px !important;
        }

        span {
          color: $lightGreen;
        }
      }
    }
  }

  .followup-rules {
    margin: 0 35px;
    color: $lightGreen;

    .followup-rules-description {
      padding-bottom: 15px;
    }

    .followup-rule-item {
      padding-bottom: 7px;
      display: flex;

      .followup-rule-icon {
        padding-right: 7px;
        padding-top: 2px;
      }
    }
  }

  .registration-list {
    margin: 50px 35px;

    .registration-list-wrapper {
      padding: 30px 30px 40px 60px;
      border-radius: 15px;
      box-shadow: 2px 2px 8px #ddd;

      .vs-table--pagination {
        margin: 20px 0;
        padding-right: 60px;
      }

      .registration-list-content {
        box-shadow: 4px 4px 8px #ddd;

        .vs-con-table .vs-con-tbody {
          border: none;
        }

        .vs-table--thead {
          color: $lightGreen;
          font-weight: 700;

          th {
            font-size: 16px;
          }
        }

        .vs-table--td {
          color: $textColor;
        }
      }

      .registration-list-header {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        .registration-title {
          font-size: 24px;
          font-weight: 700;
          color: $green;
        }

        .registration-subtitle {
          font-size: 18px;
          color: $lightGreen;
          padding-left: 10px;
          font-style: italic;
        }
      }
    }
  }
}
.vs-popup {
  .vs-popup--header {
    background: $white;
    height: 20px;
  }
  .vs-popup--close {
    border-radius: 50%;
    color: $darkGreen;
    font-weight: 700;
    box-shadow: 0 1px 1px 0 #ddd;
    transform: translate(10px,-8px);
  }
  .vs-popup--content {
    padding: 0 30px 15px;
    h4 {
      color: $darkGreen;
      font-weight: 700;
      margin-bottom: 25px;
    }
  }
  .vs-popup--content {
    margin: 0;
    width: 100%;
  }
}
.available-followers-list {
  .available-followers-item {
    background-color: $lightYellow;
    width: auto;
    margin-bottom: 0;
    .available-followers-item-content {
      padding: 30px 0;
      color: $darkGreen;
      .follower-role {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
      }
      .follower-name {
        font-size: 12px;
      }
    }
  }
  .vs-col {
    margin-bottom: 0 !important;
    &:nth-child(odd) {
      padding-right: 8px !important;
    }
    &:nth-child(even) {
      padding-left: 8px !important;
    }
    .vs-button:disabled {
      font-size: 16px;
    }
  }
}
@media (max-width: 576px) {
  .request-statistics {
    flex-direction: column;
    .statistics-card {
      &:last-child {
        margin-left: 0 !important;
        margin-top: 40px;
      }
    }
  }
}

</style>
